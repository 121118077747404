import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { createEpicMiddleware, combineEpics, Epic } from "redux-observable";
import apiStore from './api/reducer';
import routerStore from './router/reducer';
import enrollStore from './enroll/reducer';
import partnerSignup from './partner-signup/reducer';
import sidebarStore from './sidebar/reducer';
import { callApiProcessing, epicConfigEndpoint, epicConfigureGetTriggerTrigger, epicM2mAuthorizeProcessed, epicM2mAuthorizeTrigger, epicNotificationGetTrigger, epicNotificationPostTrigger, epicNotificationProcessed, epicProfilePaymentGetTrigger, epicSignupPartnerGetTrigger, epicSignupPartnerPaymentUrlTrigger, epicSignupPartnerTrigger } from './api/epic';
import { epicConfirmProcessed, epicConfirmTrigger, epicEnrollGetTrigger, epicEnrollTrigger, epicSignupTrigger } from './enroll/epic';
import { epicSignupPartnerDataFill, epicSignupPartnerFlowDATAtoREGISTER, epicSignupPartnerFlowFail, epicSignupPartnerFlowOTPtoDATA, epicSignupPartnerFlowPayment, epicSignupPartnerOTPFill } from './partner-signup/epic';
import { epicProfileStartup } from './profile/epic';

type EpicType = Epic<Action<any>, Action<any>, void, any>

const reducers = combineReducers({
  api: apiStore,
  router: routerStore,
  enroll: enrollStore,
  partnerSignup: partnerSignup,
  sidebar: sidebarStore,
});

const rootEpic = combineEpics<Action<any>, Action<any>, void, any>(
  // API Call
  callApiProcessing as unknown as EpicType,
  epicSignupPartnerGetTrigger as unknown as EpicType,
  epicSignupPartnerTrigger as unknown as EpicType,
  epicSignupPartnerPaymentUrlTrigger as unknown as EpicType,
  epicProfilePaymentGetTrigger as unknown as EpicType,
  epicM2mAuthorizeTrigger as unknown as EpicType,
  epicM2mAuthorizeProcessed as unknown as EpicType,
  epicNotificationGetTrigger as unknown as EpicType,
  epicConfigureGetTriggerTrigger as unknown as EpicType,
  epicNotificationPostTrigger as unknown as EpicType,
  epicNotificationProcessed as unknown as EpicType,
  //
  epicSignupTrigger as unknown as EpicType,
  epicConfirmTrigger as unknown as EpicType,
  epicConfirmProcessed as unknown as EpicType,
  epicEnrollTrigger as unknown as EpicType,
  epicEnrollGetTrigger as unknown as EpicType,
  // epicLoginFlowMobileComplete as unknown as EpicType,
  // epicLoginFlowMobileFail as unknown as EpicType,
  // epicLoginProcessed as unknown as EpicType,
  // epicLoginGetTrigger as unknown as EpicType,
  // epicLoginTrigger as unknown as EpicType,
  // epicLoginPostProcessed as unknown as EpicType,
  // epicLoginPostFailed as unknown as EpicType,
  // epicLoginFlowMobileOtp as unknown as EpicType,
  // epicLoginFlowWebSentence as unknown as EpicType,
  // epicLoginFlowWebRecorderLoader as unknown as EpicType,
  // epicLoginFlowWebProcessed as unknown as EpicType,
  // epicLoginFlowWebNoMoreRetry as unknown as EpicType,
  // epicLoginFlowWebNoMoreRetryOnFail as unknown as EpicType,
  // epicM2mAuthorizeComplete as unknown as EpicType,
  // epicApiToken as unknown as EpicType,
  epicConfigEndpoint as unknown as EpicType,
  epicSignupPartnerFlowOTPtoDATA as unknown as EpicType,
  epicSignupPartnerFlowDATAtoREGISTER as unknown as EpicType,
  epicSignupPartnerOTPFill as unknown as EpicType,
  epicSignupPartnerDataFill as unknown as EpicType,
  epicSignupPartnerFlowFail as unknown as EpicType,
  epicSignupPartnerFlowPayment as unknown as EpicType,
  epicProfileStartup as unknown as EpicType,
)

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: reducers,
  middleware: [epicMiddleware],
  }
);

epicMiddleware.run(rootEpic);


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
