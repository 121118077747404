export const phrases = [
  {"phrase": "Sole mare cielo vento onde", "phrase_ar": "الشمس البحر السماء الرياح الأمواج"},
  {"phrase": "Montagne neve pini silenzio pace", "phrase_ar": "الجبال الثلج الصنوبر السكون السلام"},
  {"phrase": "Amore cuore sogni notte stelle", "phrase_ar": "الحب القلب الأحلام الليل النجوم"},
  {"phrase": "Fiume bosco sentiero fiori profumo", "phrase_ar": "النهر الغابة الطريق الزهور العطر"},
  {"phrase": "Libri mente viaggio conoscenza segreti", "phrase_ar": "الكتب العقل الرحلة المعرفة الأسرار"},
  {"phrase": "Risate amici serate ricordi felicità", "phrase_ar": "الضحك الأصدقاء السهرات الذكريات السعادة"},
  {"phrase": "Musica note emozioni danza libertà", "phrase_ar": "الموسيقى النوتات العواطف الرقص الحرية"},
  {"phrase": "Città luci vetrine passi mistero", "phrase_ar": "المدينة الأنوار المحلات الخطى الغموض"},
  {"phrase": "Colori pennelli tela arte ispirazione", "phrase_ar": "الألوان الفرش اللوحة الفن الإلهام"},
  {"phrase": "Luna cielo notte mistero magia", "phrase_ar": "القمر السماء الليل الغموض السحر"},
  {"phrase": "Bambini gioco risate abbracci futuro", "phrase_ar": "الأطفال اللعب الضحك العناق المستقبل"},
  {"phrase": "Parole pensieri poesia anima creatività", "phrase_ar": "الكلمات الأفكار الشعر الروح الإبداع"},
  {"phrase": "Avventura scoperta strada destino orizzonte", "phrase_ar": "المغامرة الاكتشاف الطريق المصير الأفق"},
  {"phrase": "Silenzio riflessione quiete pensieri serenità", "phrase_ar": "الصمت التأمل السكون الأفكار السكينة"},
  {"phrase": "Passi cammino strada destino cambiamento", "phrase_ar": "الخطوات المسير الطريق المصير التغيير"},
  {"phrase": "Natura equilibrio armonia terra vita", "phrase_ar": "الطبيعة التوازن الانسجام الأرض الحياة"},
  {"phrase": "Tramonto cielo riflessi pace contemplazione", "phrase_ar": "الغروب السماء الانعكاسات السلام التأمل"},
  {"phrase": "Speranza luce domani sorriso fiducia", "phrase_ar": "الأمل الضوء الغد الابتسامة الثقة"},
  {"phrase": "Caffè giornale mattina routine inizio", "phrase_ar": "القهوة الجريدة الصباح الروتين البداية"},
  {"phrase": "Temporale pioggia fulmini freschezza rinascita", "phrase_ar": "العاصفة المطر البروق النضارة النهضة"}
];