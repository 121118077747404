import { APIData, ConfigureGetPayload, ConfigureGetResponse, GenericError, NotificationPayload, NotificationResponse, PartnerList, ProfilePaymentGetResponse } from "../app/api/types"
import { RootState } from "../app/store"

export const selectLastAPI = (state: RootState) => state.api.apiCall["last"]
export const selectAPI = (state: RootState) => ((api: string) => state.api.apiCall[api])
export const selectIsTokenPresent = (state: RootState) => state.api.token !== undefined
export const selectConfigureGet = (state: RootState) => state.api.apiCall["configureGet"] as unknown as APIData<ConfigureGetPayload, ConfigureGetResponse>
export const selectPartnerGetAPI = (state: RootState) => state.api.apiCall["partnerGet"] as unknown as APIData<ConfigureGetPayload, PartnerList>
export const selectM2mAuthorizeAPI = (state: RootState) => state.api.apiCall["m2mAuthorize"]
export const selectNotificationPost = (state: RootState) => state.api.apiCall["notificationPost"] as unknown as APIData<NotificationPayload, NotificationResponse>

