import { createSlice } from '@reduxjs/toolkit';
import { apiToken, callApiStart, configEndpoint, configFlowSetup, INIT, signupPartnerBuilding, signupPartnerGetBuilding, profilePaymentBuilding, partnerGetBuilding, m2mAuthorizeBuilding, logout, notificationPostBuilding, notificationGetBuilding, configureGetBuilding } from './actions';
import { APIStore } from './store';
import { APIDataInit } from './types';

const initialState: APIStore = {
  request: {},
  response: {},
  flowAPI:{
    status: 'idle'
  },
  apiCall: {
    signupPartnerGet: APIDataInit(),
  }
};

const apiStore = createSlice({
  name: 'api',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    signupPartnerGetBuilding(
      signupPartnerBuilding(
        profilePaymentBuilding(
          partnerGetBuilding(
            m2mAuthorizeBuilding(
              notificationGetBuilding(
                notificationPostBuilding(
                  configureGetBuilding(
                    builder
                  )
                )
              )
            )
          )
        )
      )
    )
    .addCase(callApiStart, (state, action) => {
      state.request = action.payload
      state.response = {}
    })
    .addCase(apiToken, (state, action) => {
      state.token = action.payload
      localStorage.setItem("token", JSON.stringify(action.payload))
    })
    .addCase(logout, (state, action) => {
      state.token = undefined
      localStorage.removeItem("token")
    })
    .addCase(configEndpoint, (state, action) => {
      if (state.api && action.payload.environments.length > 0) {
        state.api.endpoint = `https://${action.payload.environments[0].url}/`
        localStorage.setItem("url", state.api.endpoint)
      }
    })
    .addCase(configFlowSetup, (state, action) => {
      if (state.api) {
        state.flowAPI.status = action.payload.state
      }
    })
    .addCase(INIT, (state, action) => {
      if (!state.api) {
        state.api = {
          confirm: "voiceme/profile/confirm/",
          endpoint: localStorage.getItem("url") || '',
          enroll: "voiceme/enroll/",
          login: "voiceme/authorize/",
          notifications: "voiceme/notifications/",
          signup: "voiceme/signup/",
          partnerSignup: "voiceme/signup/partner/",
          profilePayment: "voiceme/profile/payment/",
          partner: "voiceme/partner/",
          m2mAuthorize: "voiceme/m2m/authorize/",
        }
      }
      const tokenString = localStorage.getItem("token")
      if(tokenString) {
        state.token = JSON.parse(tokenString)
      }
    })
  },
});

export default apiStore.reducer;
