import { ReactElement, useEffect, useRef, useState, VideoHTMLAttributes } from "react";

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
    srcObject: MediaStream 
    overlay?: ReactElement<any, any>
}
const VideoPlayer = ({ overlay, srcObject, ...props }: PropsType) => {
    const refVideo = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!refVideo.current) return
        refVideo.current.srcObject = srcObject
    }, [srcObject])
    
    return (
        <div style={{position: "relative", height: "fit-content", width: "fit-content"}}>
            <div style={{ position: "absolute", height: "100%", width: "100%", zIndex: "1000" }}>
                {overlay}
            </div>
            <div style={{height: "fit-content", width: "fit-content"}}>
                <video style={{scale: "1", width: "70vw"}} ref={refVideo} {...props} autoPlay />
            </div>
        </div>
    )
}

export default VideoPlayer;
