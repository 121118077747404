import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { signupPartnerFlowSet, signupPartnerResetFill } from '../../app/partner-signup/actions';
import { PARTNER_SIGNUP_START } from '../../app/partner-signup/types';
import { selectIsTokenPresent } from '../../app-view/api-view';
import KeySecretFlow from './KeySecretFlow';
import ProfilePage from './ProfilePage';
import PartnerSignupFlow from './PartnerSignupFlow';
import { makeStyles } from '@material-ui/core';
import { Button } from '@mui/material';
import VoiceOTPLoginFlow from './VoiceOTPLoginFlow';

const PERSONAL_LOADING = "PERSONAL_LOADING"

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "50%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
  }));

function PersonalSwitcherFlow() {
    const style = useStyles()
    const dispatch = useAppDispatch()
    const [goSignup, setGoSignup] = useState<'password' | 'signup' | 'voiceotp'>('voiceotp')
    const isTokenPresent = useSelector(selectIsTokenPresent)

    useEffect(() => {
        dispatch(signupPartnerResetFill())
        dispatch(signupPartnerFlowSet(PARTNER_SIGNUP_START))
    }, [dispatch])

    const handleGoSignup = () => {
        setGoSignup('signup')
    }
    const handleGoLogin = () => {
        setGoSignup('password')
    }
    const handleGoVoiceOTP = () => {
        setGoSignup('voiceotp')
    }
    if(!isTokenPresent) {
        switch(goSignup) {
        case 'signup':
            return (
                <PartnerSignupFlow >
                    <Button className={style.item} variant='outlined' type="button" onClick={handleGoLogin}>Already have account?</Button>
                </PartnerSignupFlow>
            )
        case 'password':
            return (
                <KeySecretFlow>
                    <Button className={style.itemHalf}  variant='outlined' type="button" onClick={handleGoSignup}>No account? Register!</Button>
                    <Button className={style.itemHalf}  variant='outlined' type="button" onClick={handleGoVoiceOTP}>Voice OTP Login</Button>
                </KeySecretFlow>
            )
        case 'voiceotp':
            return (
                <VoiceOTPLoginFlow>
                    <Button className={style.itemHalf}  variant='outlined' type="button" onClick={handleGoSignup}>No account? Register!</Button>
                    <Button className={style.itemHalf} variant='outlined' type="button" onClick={handleGoLogin}>Already have account?</Button>
                </VoiceOTPLoginFlow>
            )
        }
    } else {
        return (<ProfilePage></ProfilePage>)
    }
}
export default PersonalSwitcherFlow;
