import { ReactElement, useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import { IRecording, RecorderInstance } from './Recorder';
import VoicemeButton from '../../atoms/VoicemeButton';
import voicemeBouble from '../../../assets/images/voiceme-bouble.svg'
import { EnrollPayload } from '../../../app/enroll/types';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@mui/material';
import { Image } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
    // Define your styles here
    addressContainer: {
        // Add your styles for the container
        // Example:
        backgroundColor: 'lightgray',
        padding: theme.spacing(2),
      },
    fieldContainer: {
        padding: theme.spacing(0),
    },
    label: {
      // Add your styles for Typography components
      // Example:
      border: "1px",
      backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    text: {
      // Add your styles for Typography components
      // Example:
      border: "1px",
      backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    payment: {
        textAlign: "center",
    },
    noPayment: {
        textAlign: "center",
        color: "red",
        style: "bold",
    }
  }));

interface Props {
    sentence?: string
    secondarySentence?: string
    action: (payload: EnrollPayload)=>void
    cancel?: ()=>void
    attempt?: number
    duration?: number
    startText?: string
    overlay?: ReactElement<any, any>
}


const VoicemeRecorder = ({overlay, sentence, action, attempt, duration = 5, cancel = undefined, secondarySentence = undefined, startText = "ME" } : Props) => {
    const [videoStream, setVideoStream] = useState<MediaStream>();
    const [isRecordingStart, setIsRecordingStart] = useState<boolean>(false)
    
    const authorizeVoiceme = async ({ video, videoType, audio, audioType }: IRecording) => {
        action({
            audio: {
                type: audioType,
                dataURL: audio
            },
            video: {
                type: videoType,
                dataURL: video
            }
        })
    }
    const startRecording = (recordingDuration: number) => {
        if (videoStream) {
            if (isRecordingStart) {
                // console.log("Recording in progress");
            } else {
                RecorderInstance.startVideoRecording();
                // console.log("Recording started");
                setIsRecordingStart(true);
                setTimeout(() => {
                    requestRecording();
                }, recordingDuration * 1000);
            }
        } else {
            alert("Allow your browser to use your camera and microphone");
        }
    }

    const requestRecording = async () => {
        const recording = await RecorderInstance.requestRecording();
        // console.log("Recording stopped");
        setIsRecordingStart(false);
        authorizeVoiceme(recording);
        RecorderInstance.reinit()
    }

    const getPermissions = async () => {
        // console.log("CREATE STREAM")
        const videoStream = await RecorderInstance.getVideoStream();
        setVideoStream(videoStream);
    }

    const onClickCancel = () => {
        if(cancel) cancel()
    }

    useEffect(() => {
        if ("MediaRecorder" in window) {
            getPermissions().catch(console.error);
        } else {
            console.log(`The MediaRecorder API is not supported in your browser`);
        }
    }, [])
    const style = useStyles()
    return (
        <div>
            <Grid container spacing={1} className={style.fieldContainer}>
                <Grid item xs={12}>{sentence}</Grid>
                {secondarySentence && (<Grid item xs={12}>{secondarySentence}</Grid>)}
                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                    {videoStream && <VideoPlayer overlay={overlay} srcObject={videoStream}/>}
                </Grid>
                <Grid item xs={12}><Button style={{border: 'none', backgroundColor: (isRecordingStart ? 'rgba(255,0,0,255)' : 'rgba(0,0,0,0)')}} onClick={() => startRecording(duration)}>{startText}</Button></Grid>
                {cancel && <Grid item xs={12}><Button onClick={onClickCancel} style={{border: 'none', backgroundColor: (isRecordingStart ? 'rgba(255,0,0,255)' : 'rgba(0,0,0,0)')}} >Cancel</Button></Grid>}
            </Grid>
        </div>
    )
}

export default VoicemeRecorder;
