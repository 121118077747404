import { Action, CombinedState } from "@reduxjs/toolkit";
import { StateObservable } from "redux-observable";
import { from, interval, merge, Observable, of, Subject } from "rxjs";
import { map, filter, withLatestFrom, switchMap, catchError, mergeMap, takeUntil, tap, take, mapTo } from "rxjs/operators";
import { callApiStart, configEndpoint, signupPartnerFailed, signupPartnerGetFailed, signupPartnerGetProcessed, signupPartnerGetTrigger, profilePaymentFailed, profilePaymentProcessed, profilePaymentTrigger, signupPartnerProcessed, signupPartnerTrigger, partnerGetTrigger, partnerGetProcessed, partnerGetFailed, m2mAuthorizeTrigger, m2mAuthorizeFailed, m2mAuthorizeProcessed, notificationGetTrigger, notificationPostTrigger, notificationPostFailed, notificationPostProcessed, notificationGetProcessed, notificationGetFailed, configureGetTrigger, configureGetProcessed, configureGetFailed, apiToken, logout } from "./actions";
import { APIStore } from "./store";
import { RootState } from "../store";

const fetchTimeout = (url: string, options: RequestInit, timeout = 7000): Promise<Response> => {
  return Promise.race([
      fetch(url, options),
      new Promise<Response>((_, reject) =>
          setTimeout(() => reject(new Error('timeout')), timeout)
      )
  ]);
}

export const fetchData = ({ api, path, method, body, auth, refresh, timeout }: { api: string, path: string, method: string, body?: any, auth?: string , refresh?: string, timeout?: number }) => {
  const headers : HeadersInit = auth ? {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${auth}`,
  } : {
    "Content-Type": "application/json",
  }
  const refreshToken = `${api}account/token/refresh/`
  const url = `${api}${path}`
  return fetchTimeout(`${url}`, {
      method: method,
      headers: headers,
      body: body,
    }, timeout)
      .then(response => {
        if (!response.ok) {
          if(response.status === 401 && refreshToken !== url) {
            return fetchTimeout(`${refreshToken}`, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify({refresh: refresh}),
            }).then(response => {
              if(!response.ok) {
                return response.json().then(json => { throw {http_status: response.status, ...json} } )
              }
              if(response.status === 204) {
                return {}
              }
              return response.json();
            })
          }
          return response.json().then(json => {
            throw json;
          });
        }
        if(response.status === 204) {
          return {}
        }
        return response.json();
      });
  }

export const callApiProcessing = (
  action$: Observable<Action>,
  state$: StateObservable<CombinedState<{ api: APIStore; }>>
): Observable<Action> =>
  action$.pipe(
    filter(callApiStart.match),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      from(fetchData({
        ...action.payload,
        auth: action.payload.auth ? state.api.token?.access_token : undefined,
        refresh: action.payload.auth ? state.api.token?.refresh_token : undefined,
      })).pipe(
        map(response => action.payload.success(response)),
        catchError(error => {
          if(error.http_status === 401) {
            return of(logout(), action.payload.reject(error))
          }
          return of(action.payload.reject(error))
        })
      ))
  );

export const epicConfigEndpoint = (action$: Observable<Action>, state$: Observable<APIStore>) =>
  action$.pipe(
      filter(configureGetProcessed.match),
      withLatestFrom(state$),
      map(([action, state]) => configEndpoint({environments: action.payload.environments})));

export const epicSignupPartnerGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(signupPartnerGetTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.partnerSignup}?email=${action.payload.email}`,
              method: "GET",
              success: signupPartnerGetProcessed,
              reject: signupPartnerGetFailed,
              auth: false,
          })
      })
  );

export const epicConfigureGetTriggerTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(configureGetTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `https://api.voiceme-production.demo.infra.voiceme.id`,
              path: `/voiceme/configuration/?search=${action.payload.config}`,
              method: "GET",
              success: configureGetProcessed,
              reject: configureGetFailed,
              auth: false,
          })
      })
  );

export const epicSignupPartnerTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(signupPartnerTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.partnerSignup}`,
              method: "POST",
              body: JSON.stringify({ ...action.payload }),
              success: signupPartnerProcessed,
              reject: signupPartnerFailed,
              auth: false,
          })
      })
  );

export const epicSignupPartnerPaymentUrlTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(profilePaymentTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.profilePayment}`,
              method: "POST",
              body: JSON.stringify({ ...action.payload }),
              success: profilePaymentProcessed,
              reject: profilePaymentFailed,
              auth: true,
          })
      })
  );

export const epicProfilePaymentGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(partnerGetTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.partner}`,
              method: "GET",
              success: partnerGetProcessed,
              reject: partnerGetFailed,
              auth: true,
          })
      })
  );

export const epicM2mAuthorizeTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(m2mAuthorizeTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.m2mAuthorize}`,
              method: "POST",
              body: JSON.stringify({ ...action.payload }),
              success: m2mAuthorizeProcessed,
              reject: m2mAuthorizeFailed,
              auth: false,
          })
      })
  );

export const epicM2mAuthorizeProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(m2mAuthorizeProcessed.match),
      mergeMap(action =>
          of(
              apiToken(action.payload),
          ))
  );

export const epicNotificationGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(notificationGetTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.notifications}?session_id=${action.payload.session_id}${action.payload.secret ? '&secret=' + action.payload.secret : ""}`,
              method: "GET",
              success: notificationGetProcessed,
              reject: notificationGetFailed,
              auth: false,
          })
      })
  );

export const epicNotificationPostTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(notificationPostTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.notifications}`,
              method: "POST",
              body: JSON.stringify({ ...action.payload }),
              success: notificationPostProcessed,
              reject: notificationPostFailed,
              auth: false,
          })
      })
  );

export const epicNotificationProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(notificationPostProcessed.match),
      switchMap(action => {
        const stopAll = new Subject();
        return merge(
          interval(1500).pipe(
            mapTo(notificationGetTrigger({session_id: action.payload.session_id})),
            takeUntil(stopAll),
          ),
          action$.pipe(
            filter(notificationGetProcessed.match),
            filter(a => a.payload.session_id === action.payload.session_id),
            filter(a => a.payload.status === 'SUCCESS'),
            filter(a => a.payload.token === undefined),
            map(_a => notificationGetTrigger({
              session_id: action.payload.session_id,
              secret: action.payload.secret})
              ),
            take(1),
          ),
          action$.pipe(
            filter(notificationGetFailed.match),
            tap(_ => stopAll.next()),
            take(1),
          ),
          action$.pipe(
            filter(notificationGetProcessed.match),
            filter(a => a.payload.session_id === action.payload.session_id),
            filter(a => a.payload.status === 'SUCCESS'),
            filter(a => a.payload.token !== undefined),
            map(a => apiToken(a.payload.token!)),
            tap(a => stopAll.next()),
            take(1),
          ),
        )
      }),
  );

