import { ReactNode } from "react";
import VoicemeHeader from "../molecule/VoicemeHeader";
import VoicemeRouter from "../router/voiceme-router";
import PersistentDrawerLeft from "../molecule/VoicemeSidebar";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { Toolbar } from '@material-ui/core';
import { SidebarMenuView } from "../../type.d";
import { AdminPanelSettings, DesignServices, Engineering, GroupWork, Groups, People, Person, QueryStats, SupervisedUserCircle, SupervisorAccount } from '@mui/icons-material';

const drawerWidth = 240;

const header: SidebarMenuView = {
    items: [
    { path: "/", label: "Home" },
  ]
}

const sidebar: SidebarMenuView = {
  items: [
      { label: "Home", path: "/"  },
      { label: "Config", icon: Engineering, path: "/config", role: ['voiceme'] },
      { label: "Partners", icon: GroupWork, path: "/partners", role: ['voiceme'] },
      { label: "Admin team", icon: AdminPanelSettings, path: "/voiceme-admin", role: ['voiceme'] },
      { label: "Statistics", icon: QueryStats, path: "/voiceme-stats", role: ['voiceme'] },
      { label: "div1", divider: true, role: ['voiceme'] },
      { label: "Partner", role: ['partner'] },
      { label: "%{partner}", icon: Groups, path: "/partner", role: ['partner'] },
      { label: "Customers", icon: People, path: "/customers", role: ['partner'] },
      { label: "Partner team", icon: SupervisorAccount, path: "/partner-admin", role: ['partner'] },
      { label: "div1", divider: true, role: ['voiceme'] },
      { label: "Customer", role: ['customer'] },
      { label: "%{customer}", icon: Person, path: "/customer", role: ['customer'] },
      { label: "Services", icon: DesignServices, path: "/services", role: ['customer'] },
      { label: "Customer team", icon: SupervisedUserCircle, path: "/customer-admin", role: ['customer'] },
  ]
}
export function VoicemePage({ children }: { children?: ReactNode }) {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{ flexGrow: 1, p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Toolbar />
                <div className="App" style={{  }}>
                    <VoicemeRouter>
                        <CssBaseline />
                        {children}
                    </VoicemeRouter>
                </div>
            </Box>
        </Box>
    )
}
