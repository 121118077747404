import React, { ReactNode, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./index.scss";
import { VoicemePage } from "./components/pages/voiceme-page";
import { Provider } from "react-redux";
import { store } from "./app/store";
import ReactDOM from "react-dom";
import VoicemeLanding from "./components/pages/voiceme-landing";
import { useAppDispatch } from "./app/hooks";
import { INIT } from "./app/api/actions";
import ConfigFlow from "./components/pages/ConfigFlow";
import PartnerSignupFlow from "./components/pages/PartnerSignupFlow";
import ProfilePage from "./components/pages/ProfilePage";
import KeySecretFlow from "./components/pages/KeySecretFlow";
import PersonalSwitcherFlow from "./components/pages/PersonalSwitcherFlow";
import ErrorPage from "./components/pages/voiceme-error-page";
import VoiceOTPLoginFlow from "./components/pages/VoiceOTPLoginFlow";



const router = createBrowserRouter([
  {
    path: "/",
    element: <VoicemeLanding />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/secretLogin",
    element: <VoicemePage><KeySecretFlow></KeySecretFlow></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/config",
    element: <VoicemePage><ConfigFlow ></ConfigFlow></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/signup-partner",
    element: <VoicemePage><PartnerSignupFlow ></PartnerSignupFlow></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/profile",
    element: <VoicemePage><ProfilePage></ProfilePage></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/personal",
    element: <VoicemePage><PersonalSwitcherFlow></PersonalSwitcherFlow></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/voiceotp",
    element: <VoicemePage><VoiceOTPLoginFlow></VoiceOTPLoginFlow></VoicemePage>,
    errorElement: <ErrorPage />
  }
]);

export function InitVoiceme({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(INIT())
  }, [dispatch])
  return (<> {children} </>)
}

ReactDOM.render(
  <Provider store={store} >
    <React.StrictMode>
      <InitVoiceme><RouterProvider router={router} /></InitVoiceme>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
