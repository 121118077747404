import { useAppDispatch } from '../../app/hooks';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { m2mAuthorizeClear, m2mAuthorizeTrigger, notificationPostTrigger } from '../../app/api/actions';
import { useSelector } from 'react-redux';
import { selectNotificationPost } from '../../app-view/api-view';
import Message from '../organism/Message';
import FormEmail from '../organism/FormEmail';
import { NotificationResponse } from '../../app/api/types';
import VoicemeRecorder from '../organism/recorder/VoicemeRecorder';
import { fetchData } from '../../app/api/epic';
import { Button, Icon, SvgIcon } from '@mui/material';
import { phrases } from './phrases';
import {ReactComponent as PassaportOutline} from '../../assets/images/passaport-outline.svg';
import {ReactComponent as PhotoOutline} from '../../assets/images/photo-outline.svg';

function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
}
  

declare interface LoginPostPayload {
    email: string
    mobile?: string
    audio: {
        type: string,
        dataURL: string
    },
    video: {
        type: string,
        dataURL: string
    },
    session_id: string
}

declare interface EnrollPayload {
    audio: {
        type: string,
        dataURL: string
    },
    video: {
        type: string,
        dataURL: string
    },
    pin_number?: string,
    session_id?: string
}

interface DataDoc {
    voice?: string
    face?: string
    document?: string
    phrase?: string
}

function VistoFirstPage({handleGoNext} : {handleGoNext: () => void}) {
    return (
        <>
            <div>
            <h1>Benvenuto. Richiesta VISTO / بنفينوتو. ريتشيستا فيستو</h1>
            <p>Questo è il primo passo del processo di candidatura a visto . Fai clic sul pulsante in basso per iniziare a registrare il tuo viso e la tua voce.</p>
            <p dir="rtl">هذا هو أول مسار لعملية الترشيح. انقر فوق النبض في الأسفل لبدء تسجيل رؤيتك وصوتك</p>
            </div>
            <Button onClick={handleGoNext}>Start a new request</Button>
        </>
    );
}
    
interface Prop {
    children?: ReactNode,
}
function DocumentRequestFlow({children} : Prop) {
    const [phraseId, setPhraseId] = useState<number>(getRandomInt(0, phrases.length));
    const [currentPage, setCurrentPage] = useState<'submit'|'first' | 'doc' | 'face' | 'done'>('first');
    const [payload, setPayload] = useState<DataDoc>({});
    const handleRecording = (p: LoginPostPayload | EnrollPayload) => {
        setPayload({
            ...payload,
            voice: p.audio.dataURL,
            face: p.video.dataURL,
        })
        setCurrentPage('doc')
    }
    const handleGoDoc = () => {
        setCurrentPage('doc')
    }
    const handleGoFace = () => {
        setCurrentPage('face')
    }
    const handleRecordingDocument = (p: LoginPostPayload | EnrollPayload) => {
        setPayload({
            ...payload,
            document: p.video.dataURL,
        })
        setCurrentPage('submit')
    }
    const handleManageUpload = () => {
        fetchData({
            api: "https://api.filemanager-dev.demo.infra.voiceme.id",
            path: "/filemanager/uploads/",
            method: "POST",
            timeout: 1000000,
            body: JSON.stringify({
                ...payload,
                phrase: phrases[phraseId].phrase,
                phrase_ar: phrases[phraseId].phrase_ar,
            })
        }).then(res => {
            setCurrentPage('done')
        })
    }
    useEffect(() => {
        if(currentPage === 'submit') {
            handleManageUpload()
        }
    }, [currentPage])
    if(currentPage === 'first') {
        return (
            <>
                <VistoFirstPage handleGoNext={handleGoFace}></VistoFirstPage>
            </>
        );
    }
    if(currentPage === 'face') {
        return (
            <>
                <div>
                    <h1>Benvenuto. Richiesta VISTO / بنفينوتو. ريتشيستا فيستو</h1>
                    <p>Registra il video inquadrando il viso nel cerchio e leggendo la frase di seguito riportato.</p>
                    <p dir="rtl">قم بتسجيل الفيديو بأربعة مشاهدات من خلال البحث وسرد العبارة التالية التي تم الرد عليها.</p>
                </div>
                <VoicemeRecorder key="face" overlay={(<PhotoOutline style={{height: "100%", width: "100%"}}/>)} sentence={phrases[phraseId].phrase} secondarySentence={phrases[phraseId].phrase_ar} duration={5} attempt={1} action={handleRecording} startText="Registra viso e frase / سجل العبارة والصورة"></VoicemeRecorder>
                {/* <Button onClick={() => handleGoDoc()}>Go to Scan Document</Button> */}
            </>
        );
    }
    if(currentPage === 'done') {
        return (
            <>
                <div>
                    <h2>Grazie per aver inviato la richiesta! / شكرًا لك على دعوة الأغنياء!</h2>
                    <Button onClick={() => window.location.reload()}>Nuova richiesta / نوفا ريتشيستا</Button>
                </div>
            </>
        );
    }
    return (
        <>
            <div>
                <h1>Benvenuto. Richiesta VISTO / بنفينوتو. ريتشيستا فيستو</h1>
                <p>Registrare un video centrando il passaporto nel disegno.</p>
                <p dir="rtl">هل تريد تسجيل فيديو مركزي لجواز السفر بدون تصميم.</p>
            </div>
            <VoicemeRecorder overlay={(<PassaportOutline  style={{height: "100%", width: "100%"}}/>)}  key="doc" sentence={""} duration={5} attempt={1} action={handleRecordingDocument} startText="Registra il documento / سجل الوثيقة"></VoicemeRecorder>
        </>
    );
}
export default DocumentRequestFlow;
