import VoiceOTPLoginFlow from './VoiceOTPLoginFlow';
import { VoicemePage } from './voiceme-page';
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import DocumentRequestFlow from './DocumentRequestFlow';

interface Props  {

}


const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "50%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
  }));

function VoicemeLanding({ }: Props) {
    const style = useStyles()

    const handleGoSignup = () => {
    }
    const handleGoLogin = () => {
    }
    const handleGoVoiceOTP = () => {
    }
    return (
        <VoicemePage>
            <DocumentRequestFlow>
                    <Button className={style.itemHalf}  variant='outlined' type="button" onClick={handleGoSignup}>No account? Register!</Button>
                    <Button className={style.itemHalf} variant='outlined' type="button" onClick={handleGoLogin}>Already have account?</Button>
            </DocumentRequestFlow>
        </VoicemePage> 
    );
}

export default VoicemeLanding;
